.App {
  text-align: center;
  background: rgb(177,208,177);
  background: linear-gradient(180deg, rgba(177,208,177,1) 0%, rgba(177,208,177,1) 25%, rgba(241,245,241,1) 45%, rgba(241,245,241,1) 55%, rgba(177,208,177,1) 75%, rgba(177,208,177,1) 100%);
  height: 100vh;
  width: 100vw;
}
.App-input {
  border-radius: 20px;
  border: solid 3px gray;
  padding: 5px;
  font-size: 20px;
  width: 150px;
}

.App-button {
  background-color: #0b6400;
  padding: 3px;
  color:white;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
}

.App-menu-unit { padding: 5px; padding-left: 10px; padding-right: 10px; color: white; cursor: pointer;  height: 23px; transition: background-color 1s;}
.App-menu-unit:hover { background-color: #2E9A20; }

.App-Loading-container { 
  display: flex;
  height: 100vh;
  width: 100vw; 
  align-items: center;
  justify-content: center;
  background-color: red;
}

.App-logo {
  width: 300px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-scale infinite 2s linear;
  }
}

.menu-mini { display:none; }
@media only screen and (max-width: 1380px) {
  .menu-bar {display:none;}
  .menu-mini { display:block }
}

.mini-logo {display:none;}
@media only screen and (max-width: 600px) {
  .main-logo {display:none;}
  .mini-logo {display:block}
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.loginUser {
  width: 50px;
  height: 50px;
  background-color: #405394;
  color: white;
  line-height: 50px;
  font-size: 30px;
  text-align: center;
  border-radius: 50px;
  text-transform: uppercase;
  cursor: pointer;
}

.thebox {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.subcat {
  background-color: gray;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px; 
  padding:5px;
  white-space:nowrap;
  cursor: pointer;
}
.bubble {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  background-color: #0b6400;
  border-radius: 50px;
  padding: 10px;
}

@keyframes App-logo-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.App-resultMsg {
  position: relative;
  padding: 30px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;

}

.Search-button {
  border-radius: 20px;
  border: solid 3px white;
  padding: 3px;
  font-size: 20px;
  width: 150px;
  text-align: center;
  background-color: #405394;
  color:white;
  cursor: pointer;
}